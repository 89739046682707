<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column>
      <v-layout wrap justify-center class="ma-0">
          <v-flex xs12 sm11 md10>
            <v-layout column class="pa-4">
              <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('sections.refund')}}</h2>
              <v-layout wrap class="my-3" align-center>
                <v-flex xs12 sm6>
                  <v-text-field
                    autocomplete="off"
                    :label="$t('fields.order_reference')"
                    :readonly="state.loading"
                    v-model="reference"
                    clearable
                    outlined
                    hide-details
                    autofocus
                    v-on:keyup.enter.prevent="reference ? getOrder() : false"
                    append-icon="mdi-magnify"
                  />
                </v-flex>
                <v-btn :loading="loadingOrder" :disabled="!reference" @click="getOrder()" class="ml-4" color="primary">{{$t('actions.locate')}}</v-btn>
              </v-layout>
              <v-alert outlined :value="displayMissingOrder" icon="mdi-alert-outline" type="warning">
                {{$t('order_refund.missing_order')}}
              </v-alert>
              <!-- LIST -->
              <order-list
                v-if="orders.list.length && !loadingRefresh"
                :listId="table"
                :list="orders.list"
                :refreshColumnDecoration="refreshColumnDecoration"
                @refund="refresh()"
                class="mt-2"
              />
              <v-layout v-else-if="loadingRefresh" justify-center align-center class="mt-10">
                <v-progress-circular indeterminate color="primary" />
              </v-layout>
            </v-layout>
          </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import vuetifyMixins from '@/mixins/Vuetify'

export default {
  data () {
    return {
      reference: null,
      displayMissingOrder: false,
      refreshColumnDecoration: null,
      loadingRefresh: false,
      loadingOrder: false,
      table: 'RefundOrder'
    }
  },
  computed: {
    ...mapState(['state', 'orders']),
    ...mapGetters({
      facilityId: 'configuration/parkFacilityId'
    })
  },
  methods: {
    getOrder () {
      this.loadingOrder = true
      this.$store.commit('orders/resetList')
      this.$store.dispatch('orders/getByReference', { reference: this.reference, save: true }).then(() => {
        this.refreshColumnDecoration = new Date()
        this.$store.dispatch('orders/decorateRefunded')
      }).catch(() => {
        this.displayMissingOrder = true
      }).finally(() => {
        this.loadingOrder = false
      })
    },
    refresh () {
      this.loadingRefresh = true
      this.$store.dispatch('orders/get', { id: this.orders.list[0].id }).then((order) => {
        this.$store.commit('orders/setList', [order])
        this.$store.dispatch('orders/decorateRefunded')
        this.refreshColumnDecoration = new Date()
      }).finally(() => {
        this.loadingRefresh = false
      })
    }
  },
  watch: {
    reference () {
      this.displayMissingOrder = false
    }
  },
  mixins: [
    vuetifyMixins
  ],
  components: {
    OrderList: () => import('@/components/view-components/OrderList.vue')
  },
  beforeDestroy () {
    this.$store.commit('orders/resetList')
  }
}
</script>
